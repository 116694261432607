// Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase,
// DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper,
// SplitText, CustomBounce, CustomWiggle,
import { gsap, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);
export default () => {
  let mm = gsap.matchMedia();
  //------------------------------------------------------//
  // Setup 🧦 GSAP and register 📜 ScrollTrigger
  // Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
  // Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
  //------------------------------------------------------//
  // gsap.registerPlugin(Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase, DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper, SplitText, CustomBounce, CustomWiggle);

  ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause",
    markers: window.location.hostname.endsWith(".local") ? true : false,
  });
    //--------------------------------//
    // ⭐️ Kenmerken 
    //--------------------------------//
    document.querySelectorAll(".ACF-kenmerken").forEach(function (container) {
      const q = gsap.utils.selector(container);
      const item = container.querySelectorAll(".item");

      let targets = q(".item");
      let numberOfTargets = targets.length

      let duration = 0.4 //change this
      let pause = 2 // change this

      let stagger = duration + pause
      let repeatDelay = (stagger * (numberOfTargets - 1)) + pause

      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: container, // What element triggers the scroll
          scrub: false, // Add a small delay of scrolling and animation. `true` is direct
          start: "top bottom", // Can be top, center, bottom 
          end: "bottom top", // Can be top, center, bottom 
          // pin: true,
        }
      });
      function convertTextToNumber(text) {
        const numberMap = {
          one: 1,
          two: 2,
          three: 3,
          four: 4,
          five: 5,
          six: 6,
          seven: 7,
          eight: 8,
          nine: 9,
          ten: 10
        };

        // Convert the text to lowercase to ensure case insensitivity
        return numberMap[text.toLowerCase()] || null; // Return null if not found
      }
      function getSpecificClass(element, substring) {
        // Get all classes of the element
        const classes = element.classList;

        // Filter classes containing the specific substring
        const matchingClass = Array.from(classes).find(cls => cls.includes(substring));

        return matchingClass || null; // Return the matching class or null if none found
      }
      const colors = ['#51A2D3', '#F1C319', '#93C957', '#78C6AC', '#D63D4F', '#8B6CAE'];
      let playing = false;
      // animation
      timeline.from(targets, {
        y: 80,
        duration: duration,
        opacity: 0,
        stagger: {
          each: stagger,
          repeat: -1,
          onUpdate: function () {
            if (!playing) {
              playing = true;
              gsap.to(q('.alloy-shape'), {
                immediateRender: false,
                fill: colors[convertTextToNumber(getSpecificClass(this.targets()[0], 'alloy-background-').replace('alloy-background-', '')) - 1],
                onComplete: () => playing = false,
              })
            }
          },
          repeatDelay: repeatDelay
        }
      })
      timeline.to(targets, {
        y: -80,
        duration: duration,
        opacity: 0,
        stagger: {
          each: stagger,
          repeat: -1,
          repeatDelay: repeatDelay
        }
      }, stagger)
    });
  // END ⭐️ Kenmerken --------------//

  //------------------------------------------------------//
  // FAQ toggle 
  //------------------------------------------------------//
  document.querySelectorAll('.overview-faq').forEach(container => {
    const items = container.querySelectorAll('.alloy-item');
    items.forEach(item => {

      // The animation 
      const tl = gsap.timeline({ defaults: { duration: 0.3 }, reversed: true, paused: true })
      tl.set(item.querySelector('.hide'), { autoAlpha: 1 })

      tl.to(item.querySelector('.hide'), { height: "auto", })
      tl.to(item.querySelector('.icon'), { rotate: 180 }, "<")

      // The event listener
      item.addEventListener('click', e => {
        tl.reversed() ? tl.play() : tl.reverse();
      })
    });
  });
  // END FAQ toggle -------------------------------------//

  //--------------------------------//
  // Compas 
  //--------------------------------//
  document.querySelectorAll('.ACF-quickmenu.type-fancy').forEach(container => {

    const q = gsap.utils.selector(container);
    const width = window.innerWidth; // viewport width
    const height = window.innerHeight; // viewport height

    // Convert angle from -180 to 180 to 0 to 360 degrees
    const convert = gsap.utils.mapRange(180, -180, 0, 360);

    gsap.set("path", {
      svgOrigin: `500 500`
    });

    function calculateRotation(mouseX, mouseY, centerX, centerY) {
      // Calculate the angle between the mouse position and the center
      const angleRadians = Math.atan2(mouseY - centerY, mouseX - centerX);
      // Convert radians to degrees and negate it to fix direction
      let angleDegrees = -angleRadians * (180 / Math.PI);

      // Adjust so that top middle (90 degrees) is mapped to 0 degrees
      angleDegrees = (angleDegrees + 90) % 360;

      // Map the angle to 0-360 range
      return convert(angleDegrees);
    }

    document.querySelector("body").addEventListener("mousemove", (e) => {
      gsap.to(q("path"), {
        rotation: `${calculateRotation(e.x, e.y, width / 2, height / 2)}_short`,
        duration: 0.2,
        ease: "power3"
      });
    });
  });
  // END Compas --------------//


  // END Setup 🧦 GSAP -------------------------------------//
};
